import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import styles from './index.module.scss'
import FAQ from '../../components/FAQ'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="Remote Podcast Guide 2021"
          description="Tips and advice about remote podcast recording to create great episodes"
        />

        <article>
          <h1>The ultimate guide to record a podcast remotely in 2021</h1>

          <section>
            <h3>
              <strong>Choosing the right tools for the job</strong>
            </h3>

            <p>
              How to identify which tools you need to succeed—and why audio and video recording tools are
              non-negotiable.
            </p>
            <p>
              The number of tools available to use to communicate online is endless but tools should never get in the
              way of producing an excellent podcast. So when choosing a tool ask yourself few questions:
            </p>
            <ol>
              <li>Do you need to collaborate with others?</li>

              <li>Do you need to present visuals to others?</li>

              <li>
                Do I Need to see other via a <strong>VIDEO CALL</strong>
              </li>
            </ol>

            <p>
              <img src="/images/talking.jpg" alt="Womens talking" />
            </p>

            <p>
              <strong>Why use Video Calling Tools?</strong>
            </p>
            <p>
              You might ask yourself why I need a video conferencing tool while recording a Podcast. The main reason is
              up to <strong>55% of our communication is the non-verbal</strong> meaning we are picking up different
              meanings and context from body language, facial expressions and tone.
            </p>
            <p>
              When recording a podcast remotely choosing the right video tool can give your podcast a natural flow of
              conversation between you and your guest or co-podcaster.
            </p>
            <h5>
              Non-verbal Communication (context, clusters, and congruence) is key to forming a great connection with
              your podcast guest and supports in the fluidity of your discussions.{' '}
            </h5>

            <p>
              <strong>Context</strong>
            </p>
            <p>
              Is the body language in the right context. If someone is rocking back and forth arms folded on the edge of
              their seat and it’s the middle of winter outside at a bus stop and they are underdressed, then they are
              probably cold.
            </p>
            <p>If they are in a warm room then the body language is out of context.</p>
            <p>
              <strong>Clusters</strong>
            </p>
            <p>
              If someone has folded arms and that is all then they may just be comfortable so nothing needs to be read
              into it.
            </p>
            <p>
              If their arms are folded, they are frowning and leaning away from you then there is a cluster of
              behaviours all suggesting a problem something that can only be picked by visually.
            </p>
            <p>
              <strong>Congruence</strong>
            </p>
            <p>
              If someone says “I really like your idea.” But their tone of voice is aggressive, bored or sarcastic and
              their arms are folded and they are frowning then what they are saying is not congruent
            </p>
            <p>Don’t make body language the missing part while recording a podcast remotely!</p>
            <h2>The Best remote podcast tools for 2021</h2>

            <p>
              Any video conferencing software that allows you to local capture should be sufficient. We have tried and
              tested them all, and here is the best;

              <img src="/images/podcasting-call-software.jpg" alt="Womens talking" />

            </p>

            <ol>
              <li>
                <h3>Zoom</h3>
                <p>
                  The best audio and video quality due to the local recording, and allows you to easily switch between
                  microphones in addition to viewing each other when talking. The main downside of Zoom is that you have to
                  pay for group meetings over 40 minutes.
                </p>
              </li>
              <li>
                <h3>Zencastr</h3>
                <p>
                  Zencastr is remote video conferencing software on steroids just for Podcasting! Its the most feature-rich
                  for podcasts (Sperate Tracks Oer Guest, WAV, Soundboard), however, can cause some latency issues compared
                  to zoom when making long-distance calls.
                </p>
              </li>
              <li>
                <h3>Skype Call</h3>
                <p>
                  The original video call software, but still great to connect with your guests. Skype is often used for
                  remote interviews (you see many large organisations like the BBC using this on live TV). Sky also allows
                  for remote recording
                </p>
              </li>
            </ol>

            <p>
              We additionally tested the following, however, they did not make our quality cut: Whereby, Google
              Hangouts, Squadcast, Cleanfeed
            </p>
            <h3>Simple Steps to a great remote podcast interview; </h3>

            <ol>
              <li>
                Make sure your internet connection is stable If you have the option to connect over ethernet. If not try
                and find a known strong signal pot
              </li>

              <li>
                Double-check that everyone starts recording (and optionally perform a sync clap, to sync the multiple
                audio tracks)
              </li>

              <li>
                Switch to better microphones if you have them, Many video conferencing tools default to your laptop’s
                microphone.
              </li>
            </ol>

            <h3>Noise Reduction Software 🎧</h3>
            <p>
              If you have a lot of background noise when recording, it can be difficult to try and remove this in post.
              First try to move to a place with fewer disruptions, if this is not possible consider using a noise
              reduction app like <a href="https://krisp.ai/technology/">Kris</a>.
            </p>



            <section>
              <h2>Remote Podcasting FAQ</h2>
              <FAQ items={[
                {
                  'question': 'How to record podcasts remotely but keep it high quality?',
                  'answer': 'To ensure high sound quality, when recording the audio file, make sure you select your USB microphone instead of the laptop microphone in your video call software.',
                },
                {
                  'question': 'How do I record a phone call for a podcast?',
                  'answer': 'First, you should check if call recording is legal in your country & let your remote guest know you will be recording the call. We recommend installing <a href="https://support.google.com/voice/answer/115083?co=GENIE.Platform%3DAndroid&hl=en"> google voice</a>, to record via your smartphone. Go to Settings, Calls, Incoming Call Options, Record Calls. If google voice is not an option you can use third party call recording software.',
                },
                {
                  'question': 'How to record better podcasts over the phone?',
                  'answer': 'Switching to video call software like zoom or face time so you are not missing out on the non-verbal communication.',
                },
                {
                  'question': 'What is Double-ender recording?',
                  'answer': 'Doubble-ender is when everyone records sound on their own end and the host will also record all as a backup. Each guest would then be expected to send their local recording to the host.',
                },
                {
                  'question': 'What are some tips for podcasting with multiple people?',
                  'answer': 'If you are trying to social distance, and record with a group of people things can get a bit chaotic, we suggest you try to introduce some structure by having specific segments for couple experts to try and limit the size.',
                },
              ]} />

            </section>

            <p>
              <strong>Research Source:</strong>

              <ul>
                <li>
                  <a href="https://www.psychologytoday.com/us/blog/beyond-words/201109/is-nonverbal-communication-numbers-game ">
                    Psychologytoday.com
                  </a>
                </li>
              </ul>
            </p>
          </section>
        </article>
      </Layout>
    )
  }
}

export default Page
